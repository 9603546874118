import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import Button from 'components/Core/Button';
import HyperLink from 'components/Core/HyperLink';
import EmbedForm from 'components/Tools/EmbeddableForm';
import EmbedLink from 'components/Tools/EmbedLink';
import NumberInputField from 'components/Core/NumberInputField';
import GatedSubscribeForm from 'components/Tools/GatedSubscribeForm';
import useBreakpointView from 'utils/useBreakpointView';
import { getFormValid, blurFocusAndScroll, checkFormFieldValidate } from 'utils/formUtils';
import { FormContainer } from '../../styles';

const HvacProfitMarginForm = ({ gatedForm, ...otherProps }) => {
  const [gateFormCookieIncrease, setGateFormCookieIncrease] = useState(0);
  const [result, setResult] = useState({ profit_margin: 0 });
  const resultRef = useRef(null);
  const isMobile = useBreakpointView(['xs', 'sm']);

  const calcResult = (values) => {
    setResult({
      profit_margin:
        parseFloat(
          (values.billable_revenue - values.cost_price - values.overhead_costs) /
            values.billable_revenue,
        ) * 100,
    });
    setGateFormCookieIncrease(gateFormCookieIncrease + 1);
  };

  const formik = useFormik({
    initialValues: {
      cost_price: 0,
      billable_revenue: 0,
      overhead_costs: 0,
    },
    validate: (values) => {
      return {
        ...(!checkFormFieldValidate(values.billable_revenue) && {
          billable_revenue: 'Billable revenue is invalid',
        }),
        ...(!checkFormFieldValidate(values.cost_price) && { cost_price: 'Cost Price is invalid' }),
        ...(!checkFormFieldValidate(values.overhead_costs) && {
          overhead_costs: 'Overhead Costs is invalid',
        }),
      };
    },
    onSubmit: (values) => {
      blurFocusAndScroll(resultRef, isMobile);
      calcResult(values);
    },
  });

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, newValue);
  };

  const formValid = getFormValid({ ...formik });

  return (
    <FormContainer {...otherProps}>
      <div className="section-tool-page-form--wrapper">
        <div className="section-tool-page-form--container">
          <div className="p-[20px] bg-white shadow-[0_15px_40px_rgba(0,0,0,0.1)] flex flex-col md:flex-row md:p-[40px]">
            <div className="flex-1 py-0 px-0 md:px-[28px] mb-[20px] md:mb-0">
              <GatedSubscribeForm gatedForm={gatedForm} increaseCookie={gateFormCookieIncrease}>
                <div className="mb-[5px]">
                  <NumberInputField
                    id="billable_revenue"
                    label="Billable Revenue"
                    min={0}
                    max={1000000}
                    values={formik.values}
                    formValid={formValid}
                    handleChange={onHandleChange}
                  />
                </div>
                <div className="mb-[5px]">
                  <NumberInputField
                    id="cost_price"
                    label="Cost Price"
                    min={0}
                    max={1000000}
                    values={formik.values}
                    formValid={formValid}
                    handleChange={onHandleChange}
                  />
                </div>
                <div className="mb-[5px]">
                  <NumberInputField
                    id="overhead_costs"
                    label="Overhead Costs"
                    min={0}
                    max={1000000}
                    values={formik.values}
                    formValid={formValid}
                    handleChange={onHandleChange}
                  />
                </div>
                <Button shadow={false} onClick={() => formik.submitForm()} className="!w-full">
                  Calculate
                </Button>
                <EmbedLink
                  show={!otherProps.isEmbed}
                  openCopyEmbedLinkDialog={otherProps?.openCopyEmbedLinkDialog}
                  classNames="!hidden md:!flex items-center justify-center mt-[20px]"
                />
              </GatedSubscribeForm>
            </div>
            <div ref={resultRef} className="flex-1 md:sticky md:top-[90px] h-full">
              <div className="p-[30px] bg-[var(--grey-2)] flex flex-col items-center justify-start h-auto">
                <h4 className="text-[16px] font-black text-[var(--grey-8)] mb-0">RESULTS</h4>
                <p className="text-[20px] font-['Nunito Sans'] text-black mb-0 mt-[20px]">
                  Profit Margin:
                </p>
                <h4 className="text-[28px] font-extrabold text-[var(--darkest)] mb-0 mt-[10px]">
                  {result.profit_margin.toFixed(2)}%
                </h4>
                <p className="mt-[40px] mb-0 text-center">
                  Learn how we calculated this result{' '}
                  <HyperLink href="#section-tool-page-content--container">below</HyperLink>.
                </p>
              </div>
            </div>
            <EmbedLink
              show={!otherProps.isEmbed}
              openCopyEmbedLinkDialog={otherProps?.openCopyEmbedLinkDialog}
              classNames="!flex md:!hidden items-center justify-center mt-[15px]"
            />
          </div>
        </div>
      </div>
    </FormContainer>
  );
};

const HvacProfitMarginCalcForm = EmbedForm(HvacProfitMarginForm);
export default HvacProfitMarginCalcForm;
