import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import SubscribeForm from 'components/Form/SubscribeForm';
import toolboxUser from 'hooks/useToolboxStorage';
import snackbar from 'utils/snackbar';
import { GateFormContainer } from '../styles';

const GatedSubscribeForm = ({
  increaseCookie,
  children,
  gatedForm,
  cookieKey = 'gate-subscribe-count',
  cookieLimit = 3,
}) => {
  const [shouldRenderForm, setShouldRenderForm] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const getCookieNum = () => {
    return parseInt(Cookies.get(cookieKey) || 0, 10);
  };

  const setCookieNum = (num) => {
    Cookies.set(cookieKey, num);
  };

  useEffect(() => {
    if (increaseCookie > 0 && getCookieNum() < cookieLimit - 1) {
      setCookieNum(getCookieNum() + 1);
      setUpdate(!isUpdate);
    }
  }, [increaseCookie]);

  const handleSuccess = () => {
    setCookieNum(cookieLimit);
    setUpdate(!isUpdate);
    snackbar('Thanks for subscribing!');
    setTimeout(() => {
      setShouldRenderForm(false);
    }, 2000);
  };

  useEffect(() => {
    const checkShouldRender = () => {
      const shouldRender =
        !toolboxUser.isLoggedIn() && getCookieNum() === cookieLimit - 1 && gatedForm;

      setShouldRenderForm(shouldRender);
    };

    checkShouldRender();
  }, [gatedForm, cookieLimit, increaseCookie]);

  return (
    <GateFormContainer>
      {shouldRenderForm ? (
        <div className="section-gate-form--submit-form">
          <h4 className="section-gate-form--submit-form-title">{gatedForm.formTitle}</h4>
          <div data-testid="gated-subscribe-form" className="gate-form--subscribe-form">
            <SubscribeForm
              className="gate-form--subscribe-marketo-form"
              inlineMarketingForm={gatedForm}
              buttonType="secondary"
              notificationMessage="Thanks for subscribing!"
              shouldRedirect={false}
              formType="Email Capture | tools gate form"
              dataPosition="tools gate form"
              onSuccess={handleSuccess}
              notificationSnack
            />
          </div>
        </div>
      ) : (
        children
      )}
    </GateFormContainer>
  );
};

export default GatedSubscribeForm;
